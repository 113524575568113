<template>
  <my-apps />
  <div class="section">
    <recent-registrations-widget></recent-registrations-widget>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RecentRegistrationsWidget, MyApps } from "@/components";


export default defineComponent({
  components: {
    RecentRegistrationsWidget,
    MyApps
  }
});
</script>

<style></style>