
import { defineComponent } from "vue";
import { RecentRegistrationsWidget, MyApps } from "@/components";


export default defineComponent({
  components: {
    RecentRegistrationsWidget,
    MyApps
  }
});
